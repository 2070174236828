import * as React from "react";
import "./icons.scss";

export const crossSvg = (
  <svg viewBox="0 0 30 30" className="icon">
    <path
      d={`m29.082202,23.702671c0,0 0,0 0,0l-8.702823,-8.702823l8.702823,-8.702823c0,0 0,0 0,
      0c0.093712,-0.093712 0.161606,-0.203681 0.204637,-0.319387c0.118575,-0.318431 0.050681,
      -0.691368 -0.205594,-0.947643l-4.110915,-4.110915c-0.256275,-0.256275 -0.629212,
      -0.324168 -0.947643,-0.204637c-0.116662,0.043031 -0.225675,0.110925 -0.320343,0.204637c0,
      0 0,0 0,0l-8.702823,8.702823l-8.702823,-8.702823c0,0 0,0 0,0c-0.093712,-0.093712 -0.203681,
      -0.161606 -0.319387,-0.204637c-0.318431,-0.118575 -0.691368,-0.050681 -0.947643,0.204637l-4.110915,
      4.110915c-0.256275,0.256275 -0.324168,0.629212 -0.205594,0.947643c0.043031,0.116662 0.110925,
      0.225675 0.204637,0.319387c0,0 0,0 0,0l8.702823,8.702823l-8.702823,8.702823c0,0 0,0 0,0c-0.093712,
      0.093712 -0.161606,0.203681 -0.204637,0.319387c-0.118575,0.318431 -0.050681,0.691368 0.205594,
      0.947643l4.110915,4.110915c0.256275,0.256275 0.629212,0.324168 0.947643,0.205594c0.116662,
      -0.043031 0.225675,-0.110925 0.319387,-0.204637c0,0 0,0 0,0l8.702823,-8.702823l8.702823,8.702823c0,
      0 0,0 0,0c0.093712,0.093712 0.203681,0.161606 0.319387,0.204637c0.318431,0.118575 0.691368,
      0.050681 0.947643,-0.205594l4.110915,-4.110915c0.256275,-0.256275 0.324168,-0.629212 0.205594,
      -0.947643c-0.043031,-0.116662 -0.110925,-0.225675 -0.204637,-0.319387l0.000956,0z`}
    ></path>
  </svg>
);

export const arrowSvg = (
  <svg viewBox="0 0 30 30" className="icon">
    <path d="m29.5315,15l-14.063,-14.063l0,8.438l-15,0l0,11.25l15,0l0,8.438l14.063,-14.063z"></path>
  </svg>
);

export const checkSvg = (
  <svg viewBox="0 0 30 30" className="icon">
    <path d="M25.313 3.75l-14.063 14.063-6.563-6.563-4.688 4.688 11.25 11.25 18.75-18.75z"></path>
  </svg>
);

export const menuSvg = (
  <svg viewBox="0 5 30 22" className="icon">
    <path d="M1.875 5.625h26.25v5.625h-26.25zM1.875 13.125h26.25v5.625h-26.25zM1.875 20.625h26.25v5.625h-26.25z"></path>
  </svg>
);

export const spinnerSvg = (
  <svg className="icon spinner" viewBox="0 0 32 32">
    <path d="M16 9.472c-1.030 0-1.865-0.835-1.865-1.865v-5.596c0-1.030 0.835-1.865 1.865-1.865s1.865 0.835 1.865 1.865v5.596c0 1.030-0.835 1.865-1.865 1.865z"></path>
    <path d="M16 31.155c-0.644 0-1.166-0.522-1.166-1.166v-5.596c0-0.644 0.522-1.166 1.166-1.166s1.166 0.522 1.166 1.166v5.596c0 0.644-0.522 1.166-1.166 1.166z"></path>
    <path d="M11.805 10.48c-0.604 0-1.192-0.314-1.516-0.875l-2.798-4.846c-0.483-0.836-0.196-1.906 0.64-2.389s1.906-0.196 2.389 0.64l2.798 4.846c0.483 0.836 0.196 1.906-0.64 2.389-0.275 0.159-0.576 0.235-0.873 0.235z"></path>
    <path d="M22.995 29.164c-0.363 0-0.715-0.188-0.91-0.525l-2.798-4.846c-0.29-0.502-0.118-1.143 0.384-1.433s1.143-0.118 1.433 0.384l2.798 4.846c0.29 0.502 0.118 1.143-0.384 1.433-0.165 0.095-0.346 0.141-0.524 0.141z"></path>
    <path d="M8.729 13.436c-0.277 0-0.557-0.070-0.814-0.219l-4.846-2.798c-0.781-0.451-1.048-1.449-0.597-2.229s1.449-1.048 2.229-0.597l4.846 2.798c0.781 0.451 1.048 1.449 0.597 2.229-0.302 0.524-0.851 0.816-1.415 0.816z"></path>
    <path d="M28.114 23.927c-0.158 0-0.319-0.040-0.465-0.125l-4.846-2.798c-0.446-0.258-0.599-0.828-0.341-1.274s0.828-0.599 1.274-0.341l4.846 2.798c0.446 0.258 0.599 0.828 0.341 1.274-0.173 0.299-0.486 0.466-0.809 0.466z"></path>
    <path d="M7.607 17.515h-5.596c-0.837 0-1.516-0.678-1.516-1.515s0.678-1.515 1.516-1.515h5.596c0.837 0 1.516 0.678 1.516 1.515s-0.678 1.515-1.516 1.515z"></path>
    <path d="M29.989 16.933c-0 0 0 0 0 0h-5.596c-0.515-0-0.933-0.418-0.933-0.933s0.418-0.933 0.933-0.933c0 0 0 0 0 0h5.596c0.515 0 0.933 0.418 0.933 0.933s-0.418 0.933-0.933 0.933z"></path>
    <path d="M3.886 24.394c-0.483 0-0.954-0.251-1.213-0.7-0.386-0.669-0.157-1.525 0.512-1.911l4.846-2.798c0.669-0.387 1.525-0.157 1.911 0.512s0.157 1.525-0.512 1.911l-4.846 2.798c-0.22 0.127-0.461 0.188-0.698 0.188z"></path>
    <path d="M23.27 12.736c-0.322 0-0.636-0.167-0.809-0.466-0.258-0.446-0.105-1.016 0.341-1.274l4.846-2.798c0.446-0.257 1.016-0.105 1.274 0.341s0.105 1.016-0.341 1.274l-4.846 2.798c-0.147 0.085-0.307 0.125-0.465 0.125z"></path>
    <path d="M9.004 29.397c-0.218 0-0.438-0.055-0.64-0.172-0.613-0.354-0.823-1.138-0.469-1.752l2.798-4.846c0.354-0.613 1.138-0.823 1.752-0.469s0.823 1.138 0.469 1.752l-2.798 4.846c-0.237 0.411-0.668 0.641-1.112 0.641z"></path>
    <path d="M20.196 9.664c-0.158 0-0.319-0.040-0.465-0.125-0.446-0.258-0.599-0.828-0.341-1.274l2.798-4.846c0.258-0.446 0.828-0.599 1.274-0.341s0.599 0.828 0.341 1.274l-2.798 4.846c-0.173 0.299-0.486 0.466-0.809 0.466z"></path>
  </svg>
);
